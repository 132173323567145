<template>
  <div class="header-container">
    <div class="header-content">
      <div class="header-logo" @click="navTo('/home')">
        <img src="@/assets/images/logo.png" alt="" />
      </div>
      <div class="header-nav">
        <div class="header-nav-list">
          <div
            class="header-nav-items"
            :class="active == 'Home' ? 'active' : ''"
            @click="navTo('/home')"
          >
            Giftcard8
          </div>
          <div
            class="header-nav-items"
            :class="active == 'Rate' ? 'active' : ''"
            @click="navTo('/rate')"
          >
            Gift card rate
          </div>
          <div
            class="header-nav-items"
            :class="active == 'Faq' ? 'active' : ''"
            @click="navTo('/faq')"
          >
            FAQ
          </div>
          <div
            class="header-nav-items"
            :class="active == 'About' ? 'active' : ''"
            @click="navTo('/about')"
          >
            About us
          </div>
          <div class="header-nav-items" @click="goToOurBolg">
            Our blog
          </div>
        </div>
      </div>
      <div class="login-account">
        <div class="login-info">
          <el-image
            class="login-account-avatar"
            :src="
              $store.state.avatar
                ? $store.state.avatar
                : require('../assets/images/avatar_default.png')
            "
            @click="avatarClick"
          />
          <div
            class="login-login"
            v-if="$store.state.account"
            @click="loginOut"
          >
            Logout
          </div>
          <div class="login-login" v-else @click="loginIn">
            Login
          </div>
        </div>
      </div>
      <el-select
        class="country-select"
        v-model="countryName"
        @change="countryChange"
        :disabled="chooseCountry"
        v-loading="getCountryListLoading"
      >
        <el-option
          :label="item.name"
          :value="item.name"
          v-for="(item, index) in countryList"
          :key="index"
        ></el-option>
      </el-select>
      <div class="header-link">
        <div class="apple_download hvr-pulse-grow" @click="appleDownload"></div>
        <div
          class="google_download hvr-pulse-grow"
          @click="googleDownload"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { getCountryListApi, getUserInfoApi } from "@/api/index";
import store from "../store";

export default defineComponent({
  name: "Header",
  props: {
    active: {
      type: String,
      default: () => {
        return "";
      },
    },
  },
  emits: ["countryChange"],
  setup(props, { emit }) {
    const router = useRouter();
    const route = useRoute();
    const chooseCountry = ref(false);

    const navTo = (page) => {
      router.push({
        path: page,
        query: {
          ...route.query,
        },
      });
    };
    const goToOurBolg = () => {
      window.open("https://blog.giftcard8.com");
    };
    // 头像点击
    const avatarClick = () => {
      sessionStorage.getItem("token")
        ? router.push({ path: "/account" })
        : router.push({ path: "/login" });
    };
    // 退出登录
    const loginOut = () => {
      sessionStorage.clear();
      store.commit("loginAccount", "");
      store.commit("loginAvatar", "");
      store.commit("loginCurrencySymbol", "");
      window.Intercom("shutdown");
      window.Intercom("boot", {
        app_id: "p9yoo8fv",
        horizontal_padding: 30,
        vertical_padding: 20,
        action_color: "rgba(0,113,178, 0.5)",
      });
      router.push({ path: "/home" });
    };
    const loginIn = () => {
      router.push({ path: "/login" });
    };
    const toAccount = () => {
      router.push({ path: "/account" });
    };
    const appleDownload = () => {
      window.open(
        "https://apps.apple.com/cn/app/giftcard8-sell-gift-cards/id1603211026"
      );
    };
    const googleDownload = () => {
      window.open(
        "https://play.google.com/store/apps/details?id=com.yihua.giftcard"
      );
    };
    const countryId = ref(parseInt(sessionStorage.getItem("country_id")) || 14);
    const countyCurrencySymbol = ref(
      parseInt(sessionStorage.getItem("country_id")) || 14
    );
    const countryName = ref(
      sessionStorage.getItem("country_name") || "Nigeria"
    );
    const countryList = reactive([]);
    const countryChange = (e) => {
      countryList.forEach((item) => {
        if (e === item.name) {
          countryId.value = item.id;
          countyCurrencySymbol.value = item.currency_symbol;
        }
      });
      sessionStorage.setItem("country_id", countryId.value);
      sessionStorage.setItem("country_name", e);
      sessionStorage.setItem("currency_symbol", countyCurrencySymbol.value);
      emit("countryChange", countryId.value, countyCurrencySymbol.value);
    };
    const getCountryListLoading = ref(false);
    const getCountryList = () => {
      getCountryListLoading.value = true;
      getCountryListApi()
        .then((res) => {
          console.log(res);
          res.data.data &&
            res.data.data.forEach((item) => {
              countryList.push(item);
            });
          getCountryListLoading.value = false;
        })
        .catch((err) => {
          console.log(err);
          getCountryListLoading.value = false;
        });
    };
    const getUserInfo = () => {
      let data = {
        token: sessionStorage.getItem("token"),
      };
      getUserInfoApi(data)
        .then((res) => {
          console.log(res);
          if (res.data.data.avatar) {
            sessionStorage.setItem("avatar", res.data.data.avatar);
            store.commit("loginAvatar", res.data.data.avatar);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
    onMounted(() => {
      getCountryList();
      if (sessionStorage.getItem("token")) {
        chooseCountry.value = true;
        getUserInfo();
      }
    });
    return {
      navTo,
      toAccount,
      chooseCountry,
      goToOurBolg,
      avatarClick,
      loginOut,
      loginIn,
      appleDownload,
      googleDownload,
      countryId,
      countryName,
      countryList,
      countryChange,
      getCountryListLoading,
    };
  },
});
</script>

<style lang="less">
.header-container {
  position: fixed;
  width: 100%;
  height: 100px;
  left: 0;
  top: 0;
  background-color: #fff;
  z-index: 999;
  .header-content {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 100px;
    .header-logo {
      position: relative;
      min-width: 56px;
      border-radius: 10px;
      display: flex;
      overflow: hidden;
      img {
        width: 56px;
        min-width: 56px;
        height: 56px;
        min-height: 56px;
        cursor: pointer;
      }
    }
    .header-nav {
      flex: 1;
      height: 100px;
      color: #666666;
      .header-nav-list {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 100%;
        .header-nav-items {
          position: relative;
          margin-right: 100px;
          cursor: pointer;
        }
        .header-nav-items.active {
          color: #0f4392;
        }
        .header-nav-items.active::before {
          content: "";
          position: absolute;
          width: 100%;
          height: 3px;
          left: 0;
          bottom: -5px;
          background-color: #0f4392;
        }
      }
    }
    .header-link {
      display: flex;
      align-items: center;
      margin-left: 30px;

      .apple_download,
      .google_download {
        width: 30px;
        height: 30px;
        margin-right: 30px;
        background-repeat: no-repeat;
        background-position: left center;
        background-size: 100% 100%;
        cursor: pointer;
      }
      .apple_download {
        background-image: url("../assets/images/apple_header_hover.png");
      }
      .apple_download:hover {
        background-image: url("../assets/images/apple_header_hover.png");
      }
      .google_download {
        background-image: url("../assets/images/Google_header_hover.png");
      }
      .google_download:hover {
        background-image: url("../assets/images/Google_header_hover.png");
      }
    }
    .login-info {
      display: flex;
      justify-content: center;
      align-items: center;
      .login-account-avatar {
        width: 46px;
        height: 46px;
        border-radius: 50%;
        cursor: pointer;
      }
      .login-login {
        margin-left: 10px;
        cursor: pointer;
      }
    }
    .country-select {
      width: 100px;
      margin: 0 10px;
    }
  }
}
</style>
