<template>
  <div class="footer-container">
    <div class="footer-content">
      <!-- 链接 -->
      <div class="footer-introduction-link">
        <div class="footer-introduction">
          <div class="footer-logo">
            <img src="../assets/images/logo.png" @click="navTo('/home')" />
            <div>
              <p>WhatsApp:+8618179849917</p>
              <p>Email:Gcard8business@giftcard8.com</p>
            </div>
          </div>
          <div class="introduction">
            On Giftcard8, you can sell any brand new gift card at a great
            price.<br />And we believe everyone has the opportunity to make money
            through <br />superior service quality and awesome speed of trade
          </div>
          <div class="link-icon">
            <el-tooltip
              class="box-item"
              effect="light"
              :content="items.tooltip"
              placement="top"
              v-for="(items, index) in footerLinkIcon"
              :key="index"
            >
              <el-image
                :src="items.url"
                @click="linkTo(items.path)"
                lazy
              ></el-image>
            </el-tooltip>
          </div>
        </div>
        <div class="footer-link">
          <div class="company-legit">
            <div class="company">
              <div class="footer-link-title">Giftcard8</div>
              <div @click="navTo('/')">Giftcard8</div>
              <div @click="navTo('/faq')">FAQ</div>
              <div @click="navTo('/about')">About us</div>
              <div @click="navToLog()">Our Blog</div>
              <div @click="navToHelp()">Visit Help Center</div>
            </div>
            <div class="legit">
              <div class="footer-link-title">Useful Links</div>
              <div @click="navTo('/terms')">Terms & Conditions</div>
              <div @click="navTo('/privacy')">Privacy Policy</div>
              <div @click="navTo('/aml')">AML Policy</div>
              <div @click="navTo('/shipping')">Shipping Policy</div>
              <div @click="navToLicense('/about')">Our License</div>
            </div>
          </div>
          <div class="other-link">
            <a
              href="https://www.scamadviser.com/check-website/giftcard8.com"
              target="_blank"
              ><img
                src="../assets/images/logo-scamadvisor-def-2021-33a26_900x.jpg"
                alt="Check My Site on ScamAdviser.com"
                style="width:260px;height:50px;"
            /></a>
            <img
                src="../assets/images/CAC-introduces-two-new-Business-Types-for-Registration.png"
                alt="If there is no response here, please copy this link(https://search.cac.gov.ng/list ) to your search engine and type 3505320 at the address sort to check."
                style="width:260px;height:50px;"
            />
          </div>
        </div>
      </div>
      <div class="footer-audit">© 2022 Giftcard8. All right reserved</div>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive } from "vue";
import { useRouter, useRoute } from "vue-router";

export default defineComponent({
  name: "Footer",
  props: {
    active: {
      type: String,
      default: () => {
        return "";
      },
    },
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const navTo = (page) => {
      router.push({
        path: page,
        query: {
          ...route.query,
        },
      });
    };
    const navToLog = () => {
      window.open("https://blog.giftcard8.com");
    };
    const navToHelp = () => {
      window.open("https://intercom.help/wanglin-technology-limited/en");
    };
    // 如果已经在about页面就返回顶部 反正就跳转about在返回顶部
    const navToLicense = () => {
      console.log(route.path);
      if(route.path === '/about') {
        document.documentElement.scrollTop = 0;
      }else {
        router.push({
          path: '/about',
          query: {
            ...route.query,
          },
        });
      }
    };
    const footerLinkIcon = reactive([
      {
        url: require("../assets/about/footer_whatsapp.png"),
        tooltip: "WhatsApp:+8618179849917",
        path: "",
      },
      {
        url: require("../assets/about/footer_email.png"),
        tooltip: "Email:Gcard8business@giftcard8.com",
        path: "",
      },
      {
        url: require("../assets/about/footer_instagram.png"),
        tooltip: "Instagram",
        path: "https://www.instagram.com/giftcard8business/",
      },
      {
        url: require("../assets/about/footer_facebook.png"),
        tooltip: "Facebook",
        path: "https://www.facebook.com/Giftcard8-business-115623504478430",
      },
      {
        url: require("../assets/about/footer_twitter.png"),
        tooltip: "Twitter",
        path: "https://twitter.com/Giftcard_8",
      },
    ]);
    const linkTo = (path) => {
      path && window.open(path);
    };
    return {
      navTo,
      navToLog,
      navToHelp,
      navToLicense,
      footerLinkIcon,
      linkTo,
    };
  },
});
</script>

<style lang="less" scoped>
.footer-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 1);
  .footer-content {
    margin: 0 200px;
    .footer-introduction-link {
      display: flex;
      margin: 80px 0;
      .footer-introduction {
        flex: 1;
        text-align: left;
        .footer-logo {
          position: relative;
          display: flex;
          align-items: center;
          overflow: hidden;
          font-size: 18px;
          img {
            width: 60px;
            height: 60px;
            border-radius: 10px;
            cursor: pointer;
          }
        }
        .introduction {
          margin-top: 45px;
          color: rgba(0, 11, 51, 0.7);
          font-size: 16px;
          line-height: 26px;
        }
        .link-icon {
          margin-top: 40px;
          display: flex;
          .el-image {
            margin-right: 20px;
            cursor: pointer;
          }
        }
      }
      .footer-link {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        text-align: left;
        color: rgba(0, 11, 51, 1);
        .company-legit {
          display: flex;
          justify-content: flex-end;
          text-align: left;
          .company {
            font-size: 16px;
            div {
              margin-bottom: 22px;
              cursor: pointer;
            }
          }
          .legit {
            margin-left: 140px;
            font-size: 16px;
            div {
              margin-bottom: 22px;
              cursor: pointer;
            }
          }
          .footer-link-title {
            color: rgba(51, 51, 51, 1);
            font-size: 20px;
            font-weight: bold;
          }
        }
        .other-link {
          margin-top: 20px;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          a {
            margin-right: 20px;
          }
        }
      }
    }
    .footer-audit {
      width: 100%;
      height: 92px;
      line-height: 92px;
      text-align: center;
      font-weight: bold;
      border-top: 1px solid rgba(0, 11, 51, 0.15);
    }
  }
}
</style>
